import { ModifierCardBase } from './ModifierCardBase';

export function OtherModifierCardContent({
    modifier,
    setModifier,
}: {
    modifier: number;
    setModifier(modifier: number): void;
}) {
    return (
        <ModifierCardBase modifier={modifier} setModifier={setModifier}>
            <h3 className="modifier-card-header">Mixed roll</h3>
            <p className="modifier-card-block">
                It appears you are attempting a mixed roll. The subsequent modifier will be applied to the total result
                of the roll.
            </p>
        </ModifierCardBase>
    );
}
