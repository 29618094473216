import { ModifierCardBase } from './ModifierCardBase';

export function AbilityCheckModifierCardContent({
    modifier,
    setModifier,
}: {
    modifier: number;
    setModifier(modifier: number): void;
}) {
    return (
        <ModifierCardBase modifier={modifier} setModifier={setModifier}>
            <h3 className="modifier-card-header">Ability check roll</h3>
            <p className="modifier-card-block">
                It appears you are attempting an ability check roll. The subsequent modifier will be added to the total
                result.
            </p>
        </ModifierCardBase>
    );
}
