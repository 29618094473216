import { DieType } from '../enums/DieType';
import { DiceRoll } from '../components/models/DiceRoll';

export function groupResultsByDieType(throwResults: DiceRoll[]): Map<DieType, DiceRoll[]> {
    return [...throwResults].reduce((prev: Map<DieType, DiceRoll[]>, curr: DiceRoll) => {
        if (!prev.has(curr.type)) {
            prev.set(curr.type, []);
        }

        const prevValue = prev.get(curr.type);
        return prev.set(curr.type, [...(prevValue ? prevValue : []), curr]);
    }, new Map<DieType, DiceRoll[]>());
}

export function getDieGroup(groups: Map<DieType, DiceRoll[]>, type: DieType): DiceRoll[] {
    return groups.has(type) ? (groups.get(type) as DiceRoll[]) : [];
}
