import { ReactNode } from 'react';
import { DieColor } from '../models/DieColor';
import { Orientation } from '../DiePool';

type Justify = 'evenly' | 'start' | 'center';

export function DieContainer(props: {
    children: ReactNode;
    orientation?: Orientation;
    justify?: Justify;
    color?: DieColor;
    className?: string;
}) {
    return (
        <div
            className={`${props.className ?? ''} is-flex direction-${props.orientation} ${
                props.justify ? `justify-${props.justify}` : ''
            }`}
        >
            {props.children}
        </div>
    );
}
