import { ReactNode, useEffect, useRef, useState } from 'react';
import './DieSelection.scss';
import { Orientation } from './DiePool';

export function DieSelection(props: { icon: ReactNode; amount: number; orientation: Orientation }) {
    const animationDurationMs = 500;

    const firstUpdate = useRef(true);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        function shakeDice() {
            setIsAnimated(true);
        }

        function waitAndStopShake(durationMs: number) {
            setTimeout(() => {
                setIsAnimated(false);
            }, durationMs);
        }

        if (firstUpdate.current) {
            return;
        }

        shakeDice();
        waitAndStopShake(animationDurationMs);
    }, [props.amount]);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        }
    }, []);

    return (
        <div className={`is-flex direction-${props.orientation} align-items-center`}>
            <div className={`die-icon ${isAnimated ? 'animate__animated animate__headShake' : ''}`}>{props.icon}</div>
            <span className="dice-amount">{props.amount}</span>
        </div>
    );
}
