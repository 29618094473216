import { useCallback, useEffect } from 'react';

export function useInterval({ callback, delay }: { callback: () => Promise<void>; delay: number }) {
    const memoizedCallback = useCallback(callback, [callback]);

    useEffect(() => {
        const interval = setInterval(memoizedCallback, delay);
        return () => clearInterval(interval);
    }, [memoizedCallback, delay]);

    return {};
}
