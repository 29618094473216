import { DieCards } from './DieCards';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DiePool, Orientation } from './DiePool';
import { useDicerollerApi } from '../hooks/useDicerollerApi';
import { DicePool } from './models/DicePool';
import { DiceRollRecord, toDicePool } from './models/DiceRollRecord';
import { toTextRepresentation } from './models/DiceRoll';
import './MainContainer.scss';
import { useUserProfile } from '../contexts/UserProfileContext';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { useInterval } from '../hooks/useInterval';
import { ModifierCard } from './modifier/ModifierCard';
import { StyleBreakpoint } from './helpers/StyleBreakpoint';
import { Action, Fab } from 'react-tiny-fab';
import { DieContainer } from './helpers/DieContainer';
import { DieSelection } from './DieSelection';
import { DieType } from '../enums/DieType';
import { useDieTypes } from '../hooks/useDieTypes';
import { useChangeDicepool } from '../hooks/useChangeDicepool';
import { D20Icon } from './icons/d20Icon';
import { ThrowResultListContainerRow } from './result/ThrowResultListContainerRow';
import { RollType } from '../enums/RollType';
import { ClearIcon } from './icons/clear';
import { useRollMetadata } from '../contexts/RollTypeContext';

export function MainContainer() {
    const historyFetchIntervalMs = 15 * 1000;

    const appInsightsContext = useContext(AppInsightsContext);
    const { submitDicePool, fetchPlayerHistory, fetchHistory } = useDicerollerApi();
    const userProfile = useUserProfile();
    const { dieMap, clearDicePool, getDieAmount } = useChangeDicepool();
    const { rollType, modifier } = useRollMetadata();

    const [lastRolls, setLastRolls] = useState<DiceRollRecord[]>([]);
    const [history, setHistory] = useState<DiceRollRecord[]>([]);

    const fetchAndStoreHistory = useCallback(async () => {
        const history = await fetchHistory();
        setHistory(history.diceRollRecords);
    }, [fetchHistory]);

    useInterval({ callback: fetchAndStoreHistory, delay: historyFetchIntervalMs });

    useEffect(() => {
        const fetchData = async () => {
            fetchAndStoreHistory();
        };

        fetchData();
    }, [fetchAndStoreHistory, userProfile.name]);

    useEffect(() => {
        const fetchData = async () => {
            const history = await fetchHistory();
            setHistory(history.diceRollRecords);
        };

        fetchData();
    }, [fetchHistory]);

    async function onDicePoolSubmit(): Promise<void> {
        const dicePool: DicePool = Object.keys(dieMap).reduce<DicePool>(
            (previous: DicePool, current: string) => {
                const amount = dieMap[current as DieType];
                const roll = { type: current as DieType, amount, results: [], total: 0 };
                const rolls = [roll, ...previous.rolls];
                return { ...previous, rolls };
            },
            { playerName: userProfile.name, rolls: [], modifier, rollType } as DicePool,
        );

        const apiResult = await submitDicePool(dicePool);
        await fetchAndStoreHistory();
        const playerHistory = await fetchPlayerHistory(userProfile.name);
        setLastRolls(playerHistory.diceRollRecords);
        appInsightsContext?.trackEvent({ name: 'dice-rolled' }, apiResult);

        clearDicePool();
    }

    const renderModifierCard = rollType !== RollType.None;

    return (
        <StyleBreakpoint
            desktopLayout={() => (
                <div className="content desktop is-flex direction-column">
                    <div className="row is-flex direction-row top">
                        {/* <RepeatRollsPanel
                                history={lastRolls}
                                onSubmit={(record: DicePool) => onDicePoolSubmit(record)}
                            ></RepeatRollsPanel> */}
                    </div>
                    <div className="row grid columns-3 rows-2">
                        <DieCards />
                    </div>
                    <div className="row grid columns-2">
                        <DiePool onSubmit={onDicePoolSubmit} orientation={Orientation.Row} />
                        {renderModifierCard ? (
                            <div className="modifier-card row is-flex direction-column">
                                <ModifierCard />
                            </div>
                        ) : null}
                    </div>
                    {history && (
                        <div className="results-container">
                            <ul className="list unformatted is-flex direction-column">
                                {history.map((result, index) => (
                                    <li
                                        key={`${index}`}
                                        className={`is-flex direction-column result-row-card ${
                                            index === 0 ? 'first' : ''
                                        }`}
                                    >
                                        <ThrowResultListContainerRow throwResult={result} resultsPerRow={10} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
            mobileLayout={() => <MobileLayout onDicePoolSubmit={onDicePoolSubmit} history={history} />}
        />
    );
}

function MobileLayout({ onDicePoolSubmit, history }: { onDicePoolSubmit: () => void; history?: DiceRollRecord[] }) {
    const { dieTypes } = useDieTypes();
    const { getDieAmount, clearDicePool } = useChangeDicepool();
    const { rollType } = useRollMetadata();
    const userProfile = useUserProfile();

    const renderModifierCard = rollType !== RollType.None;

    return (
        <div className="content mobile is-flex direction-column">
            <div className="row is-flex direction-row top">
                {/* <RepeatRollsPanel
                history={lastRolls}
                onSubmit={(record: DicePool) => onDicePoolSubmit(record)}
            ></RepeatRollsPanel> */}
            </div>
            <div className="row grid columns-3 rows-2">
                <DieCards />
            </div>
            <div className="row is-flex">
                <div className={`dice-pool-card is-flex direction-column justify-center`}>
                    <DieContainer justify="evenly" color={userProfile.dieColor} orientation={Orientation.Row}>
                        {dieTypes.map((c, i) => (
                            <div key={i} className="selection-card is-flex">
                                <DieSelection
                                    icon={c.icon(userProfile.dieColor)}
                                    amount={getDieAmount(c.type as DieType)}
                                    orientation={Orientation.Row}
                                />
                            </div>
                        ))}
                    </DieContainer>
                </div>
            </div>

            {renderModifierCard ? (
                <div className="modifier-card row is-flex direction-column">
                    <ModifierCard />
                </div>
            ) : null}

            {history && (
                <div className="results-container">
                    <ul className="list unformatted is-flex direction-column">
                        {history.map((result, index) => (
                            <li
                                key={`${index}`}
                                className={`is-flex direction-column result-row-card ${index === 0 ? 'first' : ''}`}
                            >
                                <ThrowResultListContainerRow throwResult={result} resultsPerRow={5} />
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <Fab icon={<D20Icon fill="white" />} style={{}} event="click" alwaysShowTitle={false}>
                <Action text="Roll" onClick={() => onDicePoolSubmit()}>
                    <D20Icon fill="white" className="submit-action-button" />
                </Action>
                <Action text="Clear" onClick={() => clearDicePool()}>
                    <ClearIcon fill="white" className="clear-action-button" />
                </Action>
            </Fab>
        </div>
    );
}

function RepeatRollsPanel({ history, onSubmit }: { history: DiceRollRecord[]; onSubmit: (record: DicePool) => void }) {
    return (
        <div className="history-container">
            <ul className={`list is-flex direction-row`}>
                {history.map((item, index) => (
                    <li key={index} className="list-item">
                        <button className="repeat-button" onClick={() => onSubmit(toDicePool(item))}>
                            {`${toTextRepresentation(item.rollType, item.diceRolls, item.modifier)}`}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}
