import { ModifierIncrementButtons } from './ModifierIncrementButtons';
import './ModifierCardBase.scss';

export function ModifierCardBase({
    modifier,
    setModifier,
    children: content,
}: {
    modifier: number;
    setModifier(modifier: number): void;
    children: React.ReactNode;
}) {
    return (
        <>
            <div className="modifier-card-content">{content}</div>
            <div className="button-row is-flex">
                <ModifierIncrementButtons modifier={modifier} setModifier={setModifier} />
            </div>
        </>
    );
}
