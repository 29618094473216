import { ChangeEvent } from 'react';
import { useUserProfile } from '../contexts/UserProfileContext';

export function Profile() {
    const context = useUserProfile();

    function handleColorChange(event: ChangeEvent<HTMLSelectElement>) {
        context?.setColor(event.target.value);
    }

    function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
        context?.setName(event.target.value);
    }

    return (
        <div>
            <h1>Profile</h1>
            <label htmlFor="player-name">Player name</label>
            <input name="player-name" value={context?.name} onChange={handleNameChange} />
            <p>Current die set: {context?.dieColor}</p>
            <select onChange={handleColorChange}>
                <option value="black">Black</option>
                <option value="red">Red</option>
                <option value="violet">Violet</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
            </select>
        </div>
    );
}
