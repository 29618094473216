import { DieCard } from './DieCard';
import { useUserProfile } from '../contexts/UserProfileContext';
import { useChangeDicepool } from '../hooks/useChangeDicepool';
import { DieType } from '../enums/DieType';
import { useDieTypes } from '../hooks/useDieTypes';
import './DieCards.scss';

export function DieCards() {
    const userProfile = useUserProfile();
    const { dieTypes } = useDieTypes();
    const { addDie } = useChangeDicepool();

    return (
        <>
            {dieTypes.map((c, i) => (
                <DieCard
                    key={i}
                    label={c.label}
                    icon={c.icon(userProfile.dieColor)}
                    onIncrement={addDie(c.type as DieType)}
                ></DieCard>
            ))}
        </>
    );
}
