import { DieType } from '../../enums/DieType';
import { RollType } from '../../enums/RollType';

export type DiceRoll = {
    type: DieType;
    amount: number;
    results: number[];
    total: number;
};

export function toTextRepresentation(rollType: RollType, record: DiceRoll[], modifier: number) {
    const diesAsText = record
        .map((item) => ({
            type: item.type,
            amount: item.amount,
        }))
        .filter((item) => item.amount > 0)
        .map((item) => buildDieRollText(item.type, item.amount))
        .join(', ');

    return modifier > 0 ? `${rollType}: ${diesAsText} + ${modifier}` : `${rollType}: ${diesAsText}`;

    function buildDieRollText(type: DieType, amount: number) {
        return `${amount}${type.toLowerCase()}`;
    }
}
