import { ReactNode } from 'react';
import { DieType } from '../enums/DieType';
import { D10Icon } from '../components/icons/d10Icon';
import { D12Icon } from '../components/icons/d12Icon';
import { D20Icon } from '../components/icons/d20Icon';
import { D4Icon } from '../components/icons/d4Icon';
import { D6Icon } from '../components/icons/d6Icon';
import { D8Icon } from '../components/icons/d8Icon';
import { DieColor } from '../components/models/DieColor';

type DieConfig = {
    type: string;
    label: string;
    icon: (color: DieColor) => ReactNode;
    order: number;
};

const dieToImageMap: DieConfig[] = [
    {
        type: DieType.D20,
        label: DieType.D20.toLowerCase(),
        icon: (color: DieColor) => <D20Icon fill={color} />,
        order: 1,
    },
    {
        type: DieType.D12,
        label: DieType.D12.toLowerCase(),
        icon: (color: DieColor) => <D12Icon fill={color} />,
        order: 2,
    },
    {
        type: DieType.D10,
        label: DieType.D10.toLowerCase(),
        icon: (color: DieColor) => <D10Icon fill={color} />,
        order: 3,
    },
    {
        type: DieType.D8,
        label: DieType.D8.toLowerCase(),
        icon: (color: DieColor) => <D8Icon fill={color} />,
        order: 4,
    },
    {
        type: DieType.D6,
        label: DieType.D6.toLowerCase(),
        icon: (color: DieColor) => <D6Icon fill={color} />,
        order: 5,
    },
    {
        type: DieType.D4,
        label: DieType.D4.toLowerCase(),
        icon: (color: DieColor) => <D4Icon fill={color} />,
        order: 6,
    },
];

export function useDieTypes() {
    const sortedDieTypes = dieToImageMap.sort((a, b) => a.order - b.order);

    function getColoredIcon(type: DieType, color: DieColor): ReactNode | undefined {
        return sortedDieTypes.find((i) => i.type === type)?.icon(color);
    }

    return { dieTypes: sortedDieTypes, getColoredIcon };
}
