import { ReactNode } from 'react';
import './DieCard.scss';

export function DieCard(props: { icon: ReactNode; label: string; onIncrement: (amount: number) => void }) {
    function incrementDieAmount(amount: number) {
        props.onIncrement(amount);
    }

    return (
        <div className={`die-card is-flex direction-column justify-center`}>
            <div className="die-icon">{props.icon}</div>
            <span className="die-label">{props.label}</span>
            <div className="button-row is-flex justify-center">
                <button type="button" className="increment-button" onClick={() => incrementDieAmount(1)}>
                    +1
                </button>
                <button type="button" className="increment-button" onClick={() => incrementDieAmount(5)}>
                    +5
                </button>
            </div>
        </div>
    );
}
