import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainContainer } from './components/MainContainer';
import { Profile } from './components/Profile';
import App from './App';
import { HistoryPage } from './components/history/HistoryPage';

const domNode = document.getElementById('root');

if (!domNode) {
    throw new Error('Could not find root element');
}

const root = createRoot(domNode);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="/" element={<MainContainer />} />
                    <Route path="/history" element={<HistoryPage />} />
                    <Route path="/profile" element={<Profile />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>,
);
