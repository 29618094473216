import './ModifierIncrementButtons.scss';

export function ModifierIncrementButtons({
    modifier,
    setModifier,
}: {
    modifier: number;
    setModifier(modifier: number): void;
}) {
    return (
        <div className="modifier-buttons">
            <p className="modifier-block">
                <b>Modifier:</b> {modifier}
            </p>
            <div className="button-row">
                <button type="button" className="increment-button" onClick={() => setModifier(modifier + 1)}>
                    +1
                </button>
                <button type="button" className="increment-button" onClick={() => setModifier(modifier + 5)}>
                    +5
                </button>
                <button type="button" className="increment-button" onClick={() => setModifier(0)}>
                    Reset
                </button>
            </div>
        </div>
    );
}
