import { DieColor } from '../models/DieColor';
import './Icons.scss';

export function D8Icon({ fill, className }: { fill: DieColor; className?: string }) {
    return (
        <svg className={`svg-icon ${className ?? ''}`} viewBox="0 0 82 80" xmlns="http://www.w3.org/2000/svg">
            <title>8-sided die</title>
            <desc>D8 die</desc>
            <g transform="matrix(1, 0, 0, 1, -639.066284, -138.044403)">
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path002"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 645.816,158.775 L 689.44,171.177 L 698.484,142.433 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path003"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 691.68,171.817 L 700.32,143.337 L 717.371,191.412 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path004"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 717.76,194.217 L 690.4,173.737 L 666.133,214.62 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path005"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 664.424,213.259 L 688.48,173.097 L 645.045,160.869 Z "
                ></path>
            </g>
            <text
                id="dp_text001"
                transform="matrix(-0.055068, -0.685479, 0.996769, -0.08032, 46.071033, 24.088791)"
                fill="white"
                fontFamily="Arial"
                fontSize="28"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                8
            </text>
            <text
                id="dp_text002"
                transform="matrix(-0.573462, 0.36013, -0.533047, -0.846087, 63.212696, 21.501099)"
                fill="white"
                fontFamily="Arial"
                fontSize="24"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                5
            </text>
            <text
                id="dp_text003"
                transform="matrix(0.051241, 0.814202, -0.998006, 0.06312, 46.220509, 51.934795)"
                fill="white"
                fontFamily="Arial"
                fontSize="24"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                3
            </text>
            <text
                id="dp_text004"
                transform="matrix(0.53099, -0.335527, 0.544451, 0.838881, 25.599844, 53.118786)"
                fill="white"
                fontFamily="Arial"
                fontSize="26"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                2
            </text>
        </svg>
    );
}
