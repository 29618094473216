import { DiceRollRecord } from '../components/models/DiceRollRecord';
import { DicePool } from '../components/models/DicePool';
import { FetchHistoryByPlayerResponse } from '../components/models/response/fetchHistoryByPlayerResponse';
import { FetchHistoryResponse } from '../components/models/response/fetchHistoryResponse';
import { CalculateDicePoolRollResponse } from '../components/models/response/calculateDicePoolRollResponse';
import { useCallback } from 'react';

export type DieThrowHistoryGroup = {
    playerName: string;
    diceRolls: DiceRollRecord[];
};

const enum Headers {
    ContentType = 'Content-Type',
}

const enum RequestMethod {
    GET = 'GET',
    POST = 'POST',
}

const enum ContentType {
    JSON = 'application/json',
}

export function useDicerollerApi() {
    const rootUrl = `${process.env.REACT_APP_API_BASEURL}/api`;

    async function submitDicePool(body: DicePool): Promise<CalculateDicePoolRollResponse> {
        const url = `${rootUrl}/roll`;
        const request = {
            method: RequestMethod.POST,
            headers: {
                'Content-Type': ContentType.JSON,
            },
            body: JSON.stringify(body),
        };

        return fetch(url, request).then(async (response) => {
            if (!response.ok) {
                const statusCode = response.status;
                return Promise.reject(statusCode);
            }

            const isJson = response.headers.get('Content-Type')?.includes('application/json');
            const data = isJson ? await response.json() : null;
            return data;
        });
    }

    async function fetchGroupedHistory(): Promise<DieThrowHistoryGroup[]> {
        const url = `${rootUrl}/history-grouped`;
        const request = {
            method: RequestMethod.GET,
        };

        return fetch(url, request).then(async (response) => {
            if (!response.ok) {
                const statusCode = response.status;
                return Promise.reject(statusCode);
            }

            const isJson = response.headers.get(Headers.ContentType)?.includes(ContentType.JSON);
            const data = isJson ? await response.json() : null;
            return data;
        });
    }

    const fetchPlayerHistory = useCallback(
        async (playerName: string, amount = 5): Promise<FetchHistoryByPlayerResponse> => {
            const url = `${rootUrl}/history/${playerName}/${amount}`;
            const request = {
                method: RequestMethod.GET,
            };

            const response = await fetch(url, request);
            if (!response.ok) {
                const statusCode = response.status;
                return Promise.reject(statusCode);
            }
            const isJson = response.headers.get(Headers.ContentType)?.includes(ContentType.JSON);
            const data = isJson ? await response.json() : null;
            return await data;
        },
        [rootUrl],
    );

    const fetchHistory = useCallback(async (): Promise<FetchHistoryResponse> => {
        const url = `${rootUrl}/history`;
        const request = {
            method: RequestMethod.GET,
        };

        const response = await fetch(url, request);
        if (!response.ok) {
            const statusCode = response.status;
            return Promise.reject(statusCode);
        }
        const isJson = response.headers.get(Headers.ContentType)?.includes(ContentType.JSON);
        const data = isJson ? await response.json() : null;
        return await data;
    }, [rootUrl]);

    return { submitDicePool, fetchGroupedHistory, fetchPlayerHistory, fetchHistory };
}
