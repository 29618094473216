import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();
const applicationinsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
        extensions: [reactPlugin],
    },
});

applicationinsights.loadAppInsights();
applicationinsights.trackPageView();

export { reactPlugin };
