import { DieType } from '../enums/DieType';
import { useDicePool } from '../contexts/DicePoolContext';
import { useRollMetadata } from '../contexts/RollTypeContext';
import { useEffect } from 'react';
import { RollType } from '../enums/RollType';

export function useChangeDicepool() {
    const { dieMap, setDieMap, clear } = useDicePool();
    const { setRollType } = useRollMetadata();

    function getDieAmount(type: DieType) {
        return dieMap[type] ?? 0;
    }

    function addDie(type: DieType) {
        return (amount: number) => {
            const currentAmount = getDieAmount(type);
            setDieMap(type, currentAmount + amount);
        };
    }

    useEffect(() => {
        const dieCount = Object.keys(dieMap).reduce<number>(
            (previous: number, current: string) => previous + dieMap[current as DieType],
            0,
        );

        // 1. No dice -> no roll type
        // 2. One D20 -> ability score check
        // 3. Two D20 -> advantage / disadvantage
        // 4. Standard mixed dice roll
        if (dieCount === 0) {
            setRollType(RollType.None);
        } else if (dieMap[DieType.D20] === 1 && dieCount === 1) {
            setRollType(RollType.AbilityCheck);
        } else if (dieMap[DieType.D20] === 2 && dieCount === 2) {
            // TODO: Switch between advantage and disadvantage
            setRollType(RollType.Advantage);
        } else {
            setRollType(RollType.Standard);
        }
    }, [dieMap]);

    return { dieMap: dieMap, getDieAmount, addDie, clearDicePool: clear };
}
