import Switch from 'react-switch';
import { ModifierCardBase } from './ModifierCardBase';
import { useRollMetadata } from '../../contexts/RollTypeContext';
import { RollType } from '../../enums/RollType';
import { useState } from 'react';
import './AdvantageOrDisadvantageModifierCard.scss';

export function AdvantageOrDisadvantageModifierCardContent() {
    const { modifier, rollType, setModifier, setRollType } = useRollMetadata();
    const [isEnabled, setIsEnabled] = useState(true);

    const isAdvantage = rollType === RollType.Advantage;

    function onAdvantageChange(value: boolean) {
        setRollType(value ? RollType.Advantage : RollType.Disadvantage);
    }

    function onEnabledChange(value: boolean) {
        console.log(value);
        setIsEnabled(value);

        if (!value) {
            setRollType(RollType.Standard);
        } else {
            setRollType(RollType.Advantage);
        }
    }

    return (
        <ModifierCardBase modifier={modifier} setModifier={setModifier}>
            <h3 className="modifier-card-header">Advantage or disadvantage roll</h3>
            <p className="modifier-card-block">
                It appears you are attempting to roll for an advantage or disadvantage. The subsequent modifier will be
                applied to each die roll individually unless it is disabled. In this case the modifier is added to the
                total roll.
            </p>
            <label className="is-flex direction-row modifier-card-block advantage">
                <b>Disadvantage</b>
                <Switch
                    className="advantage-switch"
                    onChange={onAdvantageChange}
                    disabled={!isEnabled}
                    checked={isAdvantage}
                />
                <b>Advantage</b>
            </label>
            <label className="is-flex direction-column modifier-card-block">
                <p className="modifier-card-block">
                    <b>Switch to standard throw:</b>
                </p>
                <Switch onChange={onEnabledChange} checked={isEnabled} />
            </label>
        </ModifierCardBase>
    );
}
