import { RollType } from '../../enums/RollType';
import { AdvantageOrDisadvantageModifierCardContent } from './AdvantageOrDisadvantageModifierCard';
import { AbilityCheckModifierCardContent } from './AbilityCheckModifierCard';
import { OtherModifierCardContent } from './OtherModifierCard';
import { useRollMetadata } from '../../contexts/RollTypeContext';

export function ModifierCard() {
    const { rollType, modifier, setModifier } = useRollMetadata();

    // Conditional rendering based on roll type state.
    return (
        <>
            {rollType === RollType.AbilityCheck ? (
                <AbilityCheckModifierCardContent modifier={modifier} setModifier={setModifier} />
            ) : rollType === RollType.Advantage || rollType === RollType.Disadvantage ? (
                <AdvantageOrDisadvantageModifierCardContent />
            ) : (
                <OtherModifierCardContent modifier={modifier} setModifier={setModifier} />
            )}
        </>
    );
}
