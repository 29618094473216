import { useChangeDicepool as useChangeDicePool } from '../hooks/useChangeDicepool';
import { useUserProfile } from '../contexts/UserProfileContext';
import { DieSelection } from './DieSelection';
import { DieContainer } from './helpers/DieContainer';
import { DicePool } from './models/DicePool';
import './DiePool.scss';
import { useDieTypes } from '../hooks/useDieTypes';
import { DieType } from '../enums/DieType';
import { useRollMetadata } from '../contexts/RollTypeContext';

export const enum Orientation {
    Row = 'row',
    Column = 'column',
}

export function DiePool(props: { onSubmit(dicePool: Record<number, number>): void; orientation: Orientation }) {
    const userProfile = useUserProfile();
    const { dieTypes } = useDieTypes();
    const { dieMap, clearDicePool, getDieAmount } = useChangeDicePool();
    const { rollType, modifier } = useRollMetadata();

    function submitDicePool() {
        const dicePool: DicePool = Object.keys(dieMap).reduce<DicePool>(
            (previous: DicePool, current: string) => {
                const amount = dieMap[current as DieType];
                const roll = { type: current as DieType, amount, results: [], total: 0 };
                const rolls = [roll, ...previous.rolls];
                return { ...previous, rolls };
            },
            { playerName: userProfile.name, rolls: [], modifier, rollType } as DicePool,
        );

        props.onSubmit(dicePool);
        clearDicePool();
    }

    return (
        <div
            className={`dice-pool-card is-flex ${
                props.orientation === Orientation.Column ? 'direction-row' : 'direction-column'
            } justify-center`}
        >
            <DieContainer justify="evenly" color={userProfile.dieColor} orientation={props.orientation}>
                {dieTypes.map((c, i) => (
                    <div key={i} className="selection-card is-flex">
                        <DieSelection
                            icon={c.icon(userProfile.dieColor)}
                            amount={getDieAmount(c.type as DieType)}
                            orientation={Orientation.Row}
                        />
                    </div>
                ))}
            </DieContainer>
            <div className={`button-row is-flex direction-${props.orientation} justify-evenly`}>
                <button className="clear-button" onClick={() => clearDicePool()}>
                    Clear
                </button>
                <button className="submit-button" onClick={() => submitDicePool()}>
                    Roll the dice!
                </button>
            </div>
        </div>
    );
}
