import { ReactNode } from 'react';
import { useDieTypes } from '../../hooks/useDieTypes';
import { useUserProfile } from '../../contexts/UserProfileContext';
import { DiceRoll } from '../models/DiceRoll';
import './SingleThrowResultColumns.scss';

export function SingleThrowResultColumns({
    itemKey,
    throwCategory,
    resultsPerRow,
}: {
    itemKey: string;
    throwCategory: DiceRoll[];
    resultsPerRow: number;
}) {
    const userProfile = useUserProfile();
    const { getColoredIcon } = useDieTypes();

    return (
        <>
            {throwCategory.map((roll: DiceRoll, categoryIndex: number) => {
                return (
                    <div className="grid list result-container" key={categoryIndex}>
                        <DieIconWithLabel
                            key={`${itemKey}-${categoryIndex}-${roll.type}`}
                            icon={getColoredIcon(roll.type, userProfile.dieColor)}
                            label={`${roll.type.toLowerCase()}`}
                        />
                        <ul
                            key={`${itemKey}-${categoryIndex}`}
                            className={`grid result-columns grid-columns-${resultsPerRow}`}
                        >
                            {roll.results.map((r, resultIndex) => (
                                <ThrowResult key={`${itemKey}-${categoryIndex}-${resultIndex}`} result={r} />
                            ))}
                        </ul>
                        <ResultTotal result={roll.total} />
                    </div>
                );
            })}
        </>
    );
}

function DieIconWithLabel({ icon, label }: { icon: ReactNode; label: string }) {
    return (
        <div className="result-die-icon is-flex direction-column ">
            <div className="die-icon">{icon}</div>
            <span className="die-label">{label}</span>
        </div>
    );
}

function ThrowResult({ result }: { result: number }) {
    return <li className="throw-result">{result}</li>;
}

function ResultTotal({ result }: { result: number }) {
    return <div className="result-total">{result}</div>;
}
