import React from 'react';
import { RollType } from '../enums/RollType';

interface RollMetadata {
    rollType: RollType;
    modifier: number;
    setModifier: (modifier: number) => void;
    setRollType: (rollType: RollType) => void;
}

const RollMetadataContext = React.createContext<RollMetadata | undefined>(undefined);

export function RollMetadataContextProvider({ children }: { children: React.ReactNode }) {
    const [state, setState] = React.useState<RollMetadata>({
        modifier: 0,
        rollType: RollType.None,
        setModifier,
        setRollType,
    });

    function setModifier(modifier: number) {
        setState((state) => ({ ...state, modifier }));
    }

    function setRollType(rollType: RollType) {
        setState((state) => ({ ...state, rollType }));
    }

    return <RollMetadataContext.Provider value={state}>{children}</RollMetadataContext.Provider>;
}

export function useRollMetadata() {
    const context = React.useContext(RollMetadataContext);
    if (context === undefined) {
        throw new Error('useRollMetadata must be used within a RollMetadataContextProvider');
    }
    return context;
}
