import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { Outlet } from 'react-router-dom';
import './App.scss';
import { UserProfileProvider } from './contexts/UserProfileContext';
import { StickyNavBar } from './components/NavBar';
import { AppInsightsContextProvider } from './contexts/AppInsightsContextProvider';
import { reactPlugin } from './telemetry/aiReactPlugin';
import { DicePoolContextProvider } from './contexts/DicePoolContext';
import { RollMetadataContextProvider } from './contexts/RollTypeContext';

function App() {
    return (
        <AppInsightsErrorBoundary onError={() => <h1>Something went wrong...</h1>} appInsights={reactPlugin}>
            <AppInsightsContextProvider>
                <UserProfileProvider>
                    <DicePoolContextProvider>
                        <RollMetadataContextProvider>
                            <StickyNavBar />
                            <div className="main-wrapper">
                                <Outlet />
                            </div>
                        </RollMetadataContextProvider>
                    </DicePoolContextProvider>
                </UserProfileProvider>
            </AppInsightsContextProvider>
        </AppInsightsErrorBoundary>
    );
}

export default App;
