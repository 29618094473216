import React, { useContext } from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { DieThrowHistoryGroup, useDicerollerApi } from '../../hooks/useDicerollerApi';
import { useUserProfile } from '../../contexts/UserProfileContext';
import { DiceRollRecord } from '../models/DiceRollRecord';
import { ThrowResultListContainerRow } from '../result/ThrowResultListContainerRow';
import './HistoryPage.scss';
import './TabsContainer.scss';

export function HistoryPage() {
    const { fetchGroupedHistory: fetchHistory } = useDicerollerApi();
    const [history, setHistory] = useState<DieThrowHistoryGroup[]>();

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetchHistory();
            setHistory(result);
        };

        fetchData();
    }, [fetchHistory]);

    return (
        <div className="history-page">
            <h1 className="text-white">Roll history</h1>
            <TabContainer>
                <TabList>
                    {history?.map((h) => (
                        <Tab key={h.playerName} label={h.playerName}>
                            {h.playerName}
                        </Tab>
                    ))}
                </TabList>
                <HistoryRollContainer history={history ?? []} />
            </TabContainer>
        </div>
    );
}

function HistoryRollContainer(props: { history: DieThrowHistoryGroup[] }) {
    const tabContext = useContext(TabContext);
    const [diceRolls, setDiceRolls] = useState<DiceRollRecord[]>();

    useEffect(() => {
        setDiceRolls(props.history.find((h) => h.playerName === tabContext?.selectedTabLabel)?.diceRolls);
    }, [tabContext?.selectedTabLabel, props.history]);

    return (
        <ul className="grid columns-2 rows-2 padding-0">
            {diceRolls?.map((value, index) => (
                <li className="is-flex direction-column roll-panel" key={`${index}`}>
                    <RollResultContainer>
                        <ThrowResultListContainerRow throwResult={value} resultsPerRow={5} />
                    </RollResultContainer>
                </li>
            ))}
        </ul>
    );
}

function RollResultContainer(props: { children: ReactNode }) {
    return <ul className="is-flex direction-column padding-0">{props.children}</ul>;
}

type TabProfile = {
    selectedTabLabel: string;
    setTabLabel: (label: string) => void;
};

const TabContext = React.createContext<TabProfile | undefined>(undefined);

function TabContainer(props: { children: ReactNode }) {
    const profileContext = useUserProfile();

    const [state, setState] = useState<TabProfile>({
        selectedTabLabel: 'Player',
        setTabLabel,
    });

    useEffect(() => {
        setTabLabel(profileContext.name);
    }, [profileContext.name, setTabLabel]);

    function setTabLabel(label: string) {
        setState({
            ...state,
            selectedTabLabel: label,
        });
    }

    return <TabContext.Provider value={state}>{props.children}</TabContext.Provider>;
}

function TabList(props: { children: ReactNode }) {
    return <ul className="tabs is-flex">{props.children}</ul>;
}

function Tab(props: { label: string; children: ReactNode }) {
    const context = useContext(TabContext);

    function onClick() {
        context?.setTabLabel(props.label);
    }

    return (
        <li
            className={`tab${context?.selectedTabLabel === props.label ? ' is-selected' : ''}`}
            onClick={() => onClick()}
        >
            {props.children}
        </li>
    );
}
