import { DieColor } from '../models/DieColor';
import './Icons.scss';

export function D12Icon({ fill, className }: { fill: DieColor; className?: string }) {
    return (
        <svg className={`svg-icon ${className ?? ''}`} viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
            <title>12-sided die</title>
            <desc>D12 die</desc>
            <defs></defs>
            <g id="dp_dp001" transform="matrix(1, 0, 0, 1, -558.340332, -141.668304)">
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path002"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 587.761,152.084 L 608.845,162.783 L 604.428,183.369 L 584.772,187.888 L 572.827,170.439 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path003"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 610.635,162.783 L 607.269,183.369 L 618.285,194.467 L 625.975,180.963 L 621.347,161.405 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path004"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 610.388,160.864 L 620.32,159.749 L 608.845,148.873 L 589.947,144.884 L 588.987,150.004 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path005"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 586.757,150.337 L 587.76,145.572 L 571.427,156.704 L 562.212,170.437 L 570.791,169.333 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path006"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 570.969,171.705 L 561.715,172.895 L 565.885,192.919 L 577.268,203.319 L 582.913,188.94 Z "
                ></path>
                <path
                    className={`fill-color-${fill}`}
                    id="dp_path007"
                    fillRule="evenodd"
                    strokeWidth="1.33333"
                    d="M 585.317,189.605 L 578.995,204.884 L 599.745,209.528 L 616.913,196.431 L 606.015,184.993 Z "
                ></path>
            </g>
            <text
                id="dp_text001"
                transform="matrix(0.97982, -0.199881, 0.199881, 0.97982, 25.946894, 38.263885)"
                fill="white"
                fontFamily="Arial"
                fontSize="21"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre', fontSize: '21px' }}
            >
                I2
            </text>
            <text
                id="dp_text002"
                transform="matrix(-1.50223, 0.585999, -0.363414, -0.931628, 42.037651, 50.150772)"
                fill="white"
                fontFamily="Arial"
                fontSize="14"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                8
            </text>
            <text
                id="dp_text003"
                transform="matrix(-0.360341, 1.57081, -0.974683, -0.22359, 55.348503, 24.956392)"
                fill="white"
                fontFamily="Arial"
                fontSize="14"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                I0
            </text>
            <text
                id="dp_text004"
                transform="matrix(-0.975109, -2.04252, 0.902435, -0.430826, 17.376398, 48.60759)"
                fill="white"
                fontFamily="Arial"
                fontSize="9"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                7
            </text>
            <text
                id="dp_text005"
                transform="matrix(2.17673, -2.24621, 0.718126, 0.695913, 13.951105, 22.126589)"
                fill="white"
                fontFamily="Arial"
                fontSize="6"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                9.
            </text>
            <text
                id="dp_text006"
                transform="matrix(1.60027, 1.85852, -0.757793, 0.652495, 38.631706, 9.7726)"
                fill="white"
                fontFamily="Arial"
                fontSize="8"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                I
            </text>
            <text
                id="dp_text007"
                transform="matrix(1.60027, 1.85852, -0.757793, 0.652495, 43.700066, 12.533693)"
                fill="white"
                fontFamily="Arial"
                fontSize="8"
                fontWeight="400"
                x="0"
                y="0"
                style={{ whiteSpace: 'pre' }}
            >
                I
            </text>
        </svg>
    );
}
