import { DiceRollRecord } from '../models/DiceRollRecord';
import { DiceRoll, toTextRepresentation } from '../models/DiceRoll';
import { SingleThrowResultColumns } from './SingleThrowResultColumns';
import { groupResultsByDieType, getDieGroup } from '../../utils/diceFunctions';
import { DieType } from '../../enums/DieType';
import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import parseJSON from 'date-fns/parseJSON';
import fi from 'date-fns/locale/fi';
import './ThrowResultListContainerRow.scss';

export function ThrowResultListContainerRow({
    throwResult,
    resultsPerRow,
}: {
    throwResult: DiceRollRecord;
    resultsPerRow: number;
}) {
    const resultGroups =
        throwResult.diceRolls.length > 0
            ? groupResultsByDieType(throwResult.diceRolls)
            : new Map<DieType, DiceRoll[]>();

    return (
        <>
            <RowHeader throwResult={throwResult} />
            <div className="is-flex direction-column">
                {Array.from(resultGroups.keys()).map((k: DieType, index: number) => (
                    <SingleThrowResultColumns
                        key={`${k}-${index}`}
                        itemKey={`${k}-${index}`}
                        throwCategory={getDieGroup(resultGroups, k)}
                        resultsPerRow={resultsPerRow}
                    />
                ))}
                <div>
                    <span>
                        {throwResult.modifier > 0
                            ? `Total: ${throwResult.result} + ${throwResult.modifier} = ${
                                  throwResult.result + throwResult.modifier
                              }`
                            : `Total: ${throwResult.result}`}
                    </span>
                </div>
            </div>
        </>
    );
}

function RowHeader({ throwResult }: { throwResult: DiceRollRecord }) {
    return (
        <div className="is-flex direction-column">
            <div className="is-flex">
                <span className="roll-player-name">{`${throwResult.playerName}`}</span>
                <span className="roll-timestamp">
                    {format(
                        utcToZonedTime(parseJSON(throwResult.timestampUtc), 'Europe/Helsinki'),
                        'dd.MM.yyyy HH:mm',
                        {
                            locale: fi,
                        },
                    )}
                </span>
            </div>
            <p className="roll-details">
                {toTextRepresentation(throwResult.rollType, throwResult.diceRolls, throwResult.modifier)}
            </p>
        </div>
    );
}
