import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { DieColor } from '../components/models/DieColor';

type UserProfile = {
    dieColor: DieColor;
    name: string;
    critRange: number;
    setColor: (color: string) => void;
    setName: (name: string) => void;
    setCritRange: (critRange: number) => void;
};

const UserProfileContext = React.createContext<UserProfile | undefined>(undefined);

function UserProfileProvider(props: { children?: ReactNode }) {
    const [config, setConfig] = useState<UserProfile>({
        dieColor: 'black',
        name: 'Player',
        critRange: 20,
        setColor,
        setName,
        setCritRange,
    });

    useEffect(() => {
        const storageValue = localStorage.getItem('profile');

        if (storageValue) {
            const parsedValue = JSON.parse(storageValue) as UserProfile;
            setConfig({ ...parsedValue, setColor, setName, setCritRange });
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('profile', JSON.stringify(config));
    }, [config]);

    function setColor(color: string) {
        if (color as DieColor) {
            setConfig((prevState) => ({ ...prevState, dieColor: color as DieColor }));
        }
    }

    function setName(name: string) {
        setConfig((prevState) => ({ ...prevState, name }));
    }

    function setCritRange(critRange: number) {
        setConfig((prevState) => ({ ...prevState, critRange: critRange }));
    }

    return <UserProfileContext.Provider value={config}>{props.children}</UserProfileContext.Provider>;
}

function useUserProfile() {
    const context = useContext(UserProfileContext);

    if (!context) {
        throw new Error('useUserProfile must be used within a CountProvider');
    }

    return context;
}

export { UserProfileProvider, useUserProfile };
