import { DieColor } from '../models/DieColor';
import './Icons.scss';

export function D4Icon({ fill, className }: { fill: DieColor; className?: string }) {
    return (
        <svg className={`svg-icon ${className ?? ''}`} viewBox="0 0 280 280" xmlns="http://www.w3.org/2000/svg">
            <title>4-sided die</title>
            <desc>D4 die</desc>
            <polygon
                className={`fill-color-${fill}`}
                stroke="#FFFFFF"
                strokeWidth="4"
                strokeMiterlimit="10"
                points="4.259 162.903 174.467 9.259 215.936 270.099"
                style={{}}
            ></polygon>
            <polygon
                className={`fill-color-${fill}`}
                stroke="#FFFFFF"
                strokeWidth="4"
                strokeMiterlimit="10"
                points="215.936 270.099 174.467 9.259 265.447 117.043"
                style={{}}
            ></polygon>
            <text
                fill="#FFFFFF"
                fontFamily="'CharlemagneStd-Bold'"
                fontSize="159.901"
                style={{
                    whiteSpace: 'pre',
                    transform: 'matrix(0.444636, 0.116312, -0.126379, 0.409218, 121.216949, 81.315742)',
                }}
            >
                4
            </text>
            <text
                fill="#FFFFFF"
                fontFamily="'CharlemagneStd-Bold'"
                fontSize="103.6155"
                style={{
                    whiteSpace: 'pre',
                    transform: 'matrix(0.113713, -0.539525, 0.272634, 0.343531, 201.023071, 90.796196)',
                }}
            >
                4
            </text>
            <text
                fill="#FFFFFF"
                fontFamily="'CharlemagneStd-Bold'"
                fontSize="63.1611"
                style={{
                    whiteSpace: 'pre',
                    transform: 'matrix(0.504959, 0.412323, -0.446023, 0.11176, 227.242554, 115.437904)',
                }}
            >
                2
            </text>
            <text
                fill="#FFFFFF"
                fontFamily="'CharlemagneStd-Bold'"
                fontSize="159.901"
                style={{
                    whiteSpace: 'pre',
                    transform: 'matrix(-0.362125, 0.264403, -0.287287, -0.333279, 171.703796, 194.781342)',
                }}
            >
                1
            </text>
            <text
                fill="#FFFFFF"
                fontFamily="'CharlemagneStd-Bold'"
                fontSize="109.8639"
                style={{
                    whiteSpace: 'pre',
                    transform: 'matrix(-0.262464, 0.379821, -0.019784, -0.425043, 226.235443, 185.647568)',
                }}
            >
                1
            </text>
            <text
                fill="#FFFFFF"
                fontFamily="'CharlemagneStd-Bold'"
                fontSize="150.6884"
                style={{
                    whiteSpace: 'pre',
                    transform: 'matrix(-0.016918, -0.425129, 0.461924, -0.01557, 86.454254, 173.935471)',
                }}
            >
                3
            </text>
        </svg>
    );
}
