import { Link } from 'react-router-dom';
import './NavBar.scss';

export function StickyNavBar() {
    return (
        <>
            <div className="navbar is-flex justify-content-center">
                <div className="logo is-flex align-items-center">
                    <svg className="vector" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                        <title>Logo</title>
                        <desc>Logo</desc>
                        <use href="/img/logo.svg#root"></use>
                    </svg>
                    <Link className="logo-label navbar-link font-body is-l is-contrast" to="/">
                        Diceroller
                    </Link>
                </div>
                <div className="nav-content is-flex align-items-center">
                    <ul className="links">
                        <li>
                            <Link className="logo-label navbar-link font-body is-l is-contrast" to="/history">
                                History
                            </Link>
                            <Link className="navbar-link font-body" to="/profile">
                                Profile
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}
