import { ReactNode, useEffect, useState } from 'react';

export function useMediaQuery(cssMediaQuery: string) {
    const [mediaQueryMatches, setMediaQueryMatches] = useState(false);

    useEffect(() => {
        // Initial query
        const query = window.matchMedia(cssMediaQuery);
        setMediaQueryMatches(query.matches);

        // Future queries on size changes
        const changeHandler = (event: MediaQueryListEvent) => setMediaQueryMatches(event.matches);
        query.addEventListener('change', changeHandler);
        return () => query.removeEventListener('change', changeHandler);
    }, [cssMediaQuery]);

    return { mediaQueryMatches };
}

export function StyleBreakpoint(props: { desktopLayout: () => ReactNode; mobileLayout: () => ReactNode }) {
    const { mediaQueryMatches } = useMediaQuery('(min-width: 568px)');

    return <>{mediaQueryMatches ? props.desktopLayout() : props.mobileLayout()}</>;
}
